<template>
  <Portlet
    title="SLA"
    class="sla"
    icon="file-contract"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="!hasAutotest">
        <template slot="buttons">
          <select v-model="quartal">
            <option :value="null">
              Yearly
            </option>
            <option value="Q1">
              Q1
            </option>
            <option value="Q2">
              Q2
            </option>
            <option value="Q3">
              Q3
            </option>
            <option value="Q4">
              Q4
            </option>
          </select>
          <select
            v-model="year"
            class="ml-2"
          >
            <option
              v-for="(yr, index) in years"
              :key="`years-${index}`"
              :value="yr"
            >
              {{ yr }}
            </option>
          </select>
        </template>
        <InstallationSlaList
          ref="list"
          :installation-id="installationId"
          :quartal="quartal"
          :year="year"
        />
      </template>
      <template v-else>
        <div class="form-group col-12 mt-3">
          <label>{{ $t("from") }}</label>
          <date-picker
            v-model="selectedDateFrom"
            :editable="true"
            :clearable="false"
            type="datetime"
            lang="en"
            format="DD.MM.YYYY HH:mm"
            value-type="date"
            :first-day-of-week="1"
            class="ml-3"
          />
          <label class="ml-4">{{ $t("to") }}</label>
          <date-picker
            v-model="selectedDateTo"
            :editable="true"
            :clearable="false"
            type="datetime"
            lang="en"
            format="DD.MM.YYYY HH:mm"
            value-type="date"
            :first-day-of-week="1"
            class="ml-3"
          />
          <label class="ml-4">
            Interval:
            <select
              v-model="selectedInterval"
              style="min-width: 90px; height: 40px"
            >
              <option
                v-for="option in intervalOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </label>
          <button
            slot="button"
            class="btn btn-primary ml-4"
            :disabled="
              !selectedInterval || !selectedDateFrom || !selectedDateTo
            "
            @click="calculateSla()"
          >
            Calculate
          </button>
        </div>
        <div
          ref="slaContainer"
          class="sla-intervals"
        >
          <LoadingPlaceholder v-if="calculationLoading" />
          <template v-else-if="calculationResult">
            <div class="font-weight-bold">
              Report was calculated with:
            </div>
            <div class="ml-1">
              - Actual start:
              {{ dateTime_dateTime(calculationResult.actualStart) }}
            </div>
            <div class="ml-1">
              - Actual end: {{ dateTime_dateTime(calculationResult.actualEnd) }}
            </div>
            <div class="font-weight-bold">
              Result:
            </div>
            <div class="ml-1">
              - Up time: {{ calculateUptime(calculationResult) }}
            </div>
            <div class="sla-container">
              <div
                v-for="(
                  calculationInterval, index
                ) of calculationResult.intervals"
                :key="index"
                v-tooltip="
                  `${dateTime_dateTime(
                    calculationInterval.intervalStart
                  )} - ${calculateUptime(calculationInterval)}`
                "
                :class="{
                  withWidth: !canFitInRow,
                  maintenance:
                    calculationInterval.downMinutes === 0 &&
                    calculationInterval.minutes === 0,
                  healthy:
                    calculationInterval.downMinutes === 0 &&
                    calculationInterval.minutes > 0,
                  unhealthy:
                    calculationInterval.downMinutes !== 0 &&
                    calculationInterval.minutes > 0 &&
                    calculationInterval.minutes ===
                    calculationInterval.downMinutes,
                  inbetween:
                    calculationInterval.downMinutes !== 0 &&
                    calculationInterval.minutes > 0 &&
                    calculationInterval.minutes >
                    calculationInterval.downMinutes,
                }"
              ></div>
            </div>
          </template>
        </div>
        <ZabbixEventList
          v-if="showList"
          ref="zabbixFalsePositiveEventList"
          class="mt-5"
          :request-object="requestObject"
          @recalculate="calculateSla"
        />
      </template>
    </template>
  </Portlet>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
export default {
  name: "InstallationDetailSla",
  components: {
    DatePicker,
    InstallationSlaList: () =>
      import("@/components/Installation/InstallationSlaList.vue"),
    ZabbixEventList: () => import("@/components/ZabbixSla/ZabbixEventList.vue"),
  },
  mixins: [dateTimeMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      quartal: null,
      year: null,
      years: null,
      selectedDateFrom: this.moment().startOf('month'),
      selectedDateTo: this.moment().add(1, 'month').startOf('month'),
      hasAutotest: false,
      loading: false,
      selectedInterval: "1d",
      calculationResult: null,
      calculationLoading: false,
      canFitInRow: false,
      requestObject: {},
      showList: false,
    };
  },
  computed: {
    intervalOptions() {
      if (!this.selectedDateFrom || !this.selectedDateTo) return [];

      const start = new Date(this.selectedDateFrom);
      const end = new Date(this.selectedDateTo);
      const diffMs = end - start;
      const diffMinutes = diffMs / 60000;
      const diffHours = diffMs / 3600000;
      const diffDays = diffMs / 86400000;

      if (diffMinutes <= 60) {
        return [
          { label: "1 minute", value: "1m" },
          { label: "5 minute", value: "5m" },
          { label: "15 minute", value: "15m" },
          { label: "30 minute", value: "30m" },
        ];
      } else if (diffHours <= 24) {
        return [
          { label: "1 minute", value: "1m" },
          { label: "5 minute", value: "5m" },
          { label: "15 minute", value: "15m" },
          { label: "30 minute", value: "30m" },
          { label: "1 hour", value: "1h" },
        ];
      } else if (diffDays <= 7) {
        return [
          { label: "15 minute", value: "15m" },
          { label: "30 minute", value: "30m" },
          { label: "1 hour", value: "1h" },
          { label: "2 hour", value: "2h" },
          { label: "6 hour", value: "6h" },
        ];
      } else if (diffDays <= 30) {
        return [
          { label: "1 hour", value: "1h" },
          { label: "2 hour", value: "2h" },
          { label: "6 hour", value: "6h" },
          { label: "12 hour", value: "12h" },
          { label: "1 day", value: "1d" },
        ];
      } else if (diffDays <= 90) {
        return [
          { label: "1 day", value: "1d" },
          { label: "2 days", value: "2d" },
          { label: "1 week", value: "7d" },
        ];
      } else if (diffDays < 365) {
        return [
          { label: "1 day", value: "1d" },
          { label: "1 week", value: "7d" },
          { label: "1 month", value: "1M" },
          { label: "quartal", value: "3M" },
        ];
      } else {
        return [
          { label: "1 week", value: "7d" },
          { label: "1 month", value: "1M" },
          { label: "quartal", value: "3M" },
          { label: "1 year", value: "1y" },
        ];
      }
    },
  },
  watch: {
    intervalOptions() {
      if (this.intervalOptions.length) {
        this.selectedInterval = this.intervalOptions[0].value;
      } else {
        this.selectedInterval = null;
      }
    },
  },
  created() {
    this.getSoftwareComponents();
    var today = new Date();
    var quarter = Math.floor((today.getMonth() + 3) / 3);
    this.quartal = `Q${quarter}`;

    this.year = new Date().getFullYear();
    let years = [];
    for (var i = this.year; i >= this.year - 2; i--) {
      years.push(i);
    }
    this.years = years;
  },
  mounted() {
    window.addEventListener("resize", this.canFitAllInOneRow);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.canFitAllInOneRow);
  },
  methods: {
    calculateUptime(calculationInterval) {
      if (calculationInterval.minutes === 0) {
        return "Planned Maintenance";
      }
      const res =
        (1 - calculationInterval.downMinutes / calculationInterval.minutes) *
        100;
      return (Math.floor(res * 100) / 100).toFixed(2) + " %";
    },
    canFitAllInOneRow() {
      const container = this.$refs.slaContainer;
      const containerWidth = container.clientWidth;
      const gap = 5;
      const itemCount = this.calculationResult.intervals.length;
      const minWidth = 10;
      const totalNeeded = itemCount * minWidth + (itemCount - 1) * gap;
      this.canFitInRow = totalNeeded <= containerWidth;
    },
    async calculateSla() {
      this.showList = false;
      var req = {
        fromDate: this.selectedDateFrom,
        toDate: this.selectedDateTo,
        installationId: this.installationId,
        interval: this.selectedInterval,
      };
      this.requestObject = req;
      this.calculationLoading = true;
      await this.axios
        .post(`/SlaCalculation/CalculateSla`, req)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.calculationResult = response.data;
          this.showList = true;
        })
        .finally(() => {
          this.calculationLoading = false;
          this.canFitAllInOneRow();
          setTimeout(() => {
            this.$nextTick(() => {
              if (this.$refs.zabbixFalsePositiveEventList) {
                this.$refs.zabbixFalsePositiveEventList.getAllEvents();
              }
            });
          }, 1000);
        });
    },
    getSoftwareComponents() {
      this.loading = true;
      this.axios
        .get(
          `/SoftwareComponent/GetSoftwareComponents?installationId=${this.installationId}`
        )
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.softwareComponents = response.data;
          if (this.softwareComponents && this.softwareComponents.length > 0) {
            this.hasAutotest = this.softwareComponents.some(
              (x) => !x.deleted && x.type.name == "Autotest"
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.sla-intervals {
  width: 100%;
  margin-top: 20px;
}

.sla-intervals > div.sla-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
}

.sla-intervals > div.sla-container:not(.loaderWrapper) > div {
  height: 30px;
  flex: 1 1 auto;
  min-width: 10px;
  border-radius: 3px;
  &.withWidth {
    max-width: 10px;
  }
  &.maintenance {
    background-color: rgb(154, 150, 150);
  }
  &.healthy {
    background-color: #08c20bca;
  }
  &.unhealthy {
    background-color: rgb(251, 8, 8);
  }
  &.inbetween {
    background-color: rgb(251, 182, 8);
  }
}
</style>
